<script>
import { mapActions } from 'vuex'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TracksManageLink',
  components: {
    Action: () => import('@/components/general/Action'),
    ActionBar: () => import('@/components/general/ActionBar'),
    ContentHeader: () => import('@/components/general/ContentHeader'),
    InputField: () => import('@/components/general/InputField'),
    Pagination: () => import('@/components/general/Pagination')
  },

  data () {
    return {
      pagination: {
        page: 4,
        lastPage: 4
      },

      formData: {
        id: null,
        solutions: [],
        linkedSolutions: []
      }
    }
  },

  validations: {
    formData: {
      linkedSolutions: { required }
    }
  },

  computed: {
    isEditing () {
      return this.$route.params.id && this.$route.params.isNew !== 'new'
    }
  },

  methods: {
    ...mapActions([
      'setFetching',
      'setFeedback',
      'attemptSaveTrack',
      'attemptUpdateTrack',
      'attemptUpdateActiveTrack',
      'attemptSaveActiveTrack'
    ]),

    prevPage () {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        // TODO: remove this
        this.$router.push({
          name: 'tracks.manage.seo',
          params: {
            id: 1,
            isNew: 'edit'
          }
        })

        if (this.formData.id != null) {
          const formData = this.formatFormData()

          this.attemptUpdateActiveTrack({
            id: this.$route.params.id,
            data: formData
          }).then(() => {
            this.$router.push({ name: 'tracks.manage.seo' })
          })
        } else {
          this.attemptSaveActiveTrack(this.formData).then(() => {
            this.$router.push({ name: 'tracks.manage.seo' })
          })
        }
      }
    },

    leave () {
      this.modalConfirm = false

      this.$nextTick(() => {
        this.$router.push(this.backUrl)
      })
    },

    submitCreation () {
      this.setFetching(true)

      this.attemptSaveTrack(this.formData).then(({ data }) => {
        this.reports.items.push(data)
        this.setFeedback({ message: this.$t('reports:feedback.created.success') })
        this.$router.push({ name: 'reports.list' })
      }).catch(({ response }) => {
        this.setFeedback({ message: this.$t(`reports:feedback.created.error:${response.data.message.replace(/_/g, '.')}`) })
        this.$router.push({ name: 'reports.list' })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submitUpdate () {
      this.setFetching(true)
      const formData = this.formatFormData()

      this.attemptUpdateTrack({
        id: this.$route.params.id,
        data: formData
      }).then(({ data }) => {
        if (data.imagePath) {
          data.imagePath = data.imagePath + '?v=' + Math.random()
        }

        this.setFeedback({ message: this.$t('tracks:feedback.updated.success') })
        this.$router.push({ name: 'tracks.index' })
      }).catch(() => {
        this.setFeedback({ message: this.$t('tracks:feedback.updated.error') })
        this.$router.push({ name: 'tracks.index' })
      }).finally(() => {
        this.setFetching(false)
      })
    },

    submit (next) {
      this.$v.$touch()

      if (!this.$v.$invalid) {
        this.$route.params.id ? this.submitUpdate(next) : this.submitCreation(next)
      } else {
        this.setFeedback({ message: this.$t('tracks:feedback.validation.error') })
      }
    },

    formatFormData () {
      const data = {}

      data.linkedSolutions = this.formData.linkedSolutions

      return data
    }
  }

  // created () {
  // this.formData = this.$store.getters.getManagingTrack
  // this.formData.canvasCourseId = this.formData.trackIntegration.length > 0 ? this.formData.trackIntegration.filter((item) => item.integration.alias == 'canvas')[0].vendorId : null
  // this.formData.trackIntegration = this.formData.trackIntegration.length > 0 ? this.formData.trackIntegration.filter((item) => item.integration.alias == 'canvas').map((item => item.integration.alias || item)) : ['canvas']
  // if (this.isEditing) {
  //   this.formData.trackTheme = this.formData.trackTheme.map((item) => item.theme.alias || item)
  //   this.formData.trackProfile = this.formData.trackEntrepreneurProfile.map((item) => item.entrepreneurProfile.id || item)
  //   if (this.formData.workload) {
  //     this.formData.workload = {
  //       number: this.formData.workload.toString().substr(0, this.formData.workload.length - 1),
  //       type: this.formData.workload.toString().substr(this.formData.workload.length - 1, 1),
  //     }
  //   }
  //   if (this.formData.duration) {
  //     this.formData.duration = {
  //       number: this.formData.duration.toString().substr(0, this.formData.duration.length - 1),
  //       type: this.formData.duration.toString().substr(this.formData.duration.length - 1, 1),
  //     }
  //   }
  // } else {
  //   this.formData.workload = {
  //     number: 0,
  //     type: '',
  //   }
  //   this.formData.duration = {
  //     number: 0,
  //     type: '',
  //   }
  // }
  // }
}
</script>

<template>
  <div class="main-content tracks-create">
    <ContentHeader
      :title="isEditing ? formData.title : $t('tracks.create:header.title')"
      light-theme
      fixed
    >
      <Action
        slot="back"
        type="button"
        :text="$t('global:back.tracks')"
        class="btn-back"
        icon="keyboard_backspace"
        @click="leave()"
      />
      <ActionBar slot="actionbar" />
      <template slot="buttons">
        <Action
          :text="$t('global:form.save')"
          type="button"
          flat
          @click="submit(false)"
        />
      </template>
    </ContentHeader>
    <div class="main-content-inner">
      <div class="center">
        <div class="tracks-create-header">
          <h2 class="tracks-create-title">
            {{ $t('global:form.step', {'num': 4}) }}
          </h2>
          <p
            class="tracks-create-description"
            v-html="$t('tracks.create:header.description.4')"
          />
        </div>
        <form
          class="form"
          @submit.prevent="submit"
        >
          <InputField
            v-model="formData.name"
            :label="$t('global:form.tracks.title')"
            :validation="$v.formData.name"
            :counter="100"
          />
          <div class="row">
            <div class="col-6">
              <h2>Soluções encontradas</h2>
            </div>
            <div class="col-6">
              <h2>Soluções vinculadas à trilha</h2>
            </div>
          </div>
        </form>
      </div>
    </div>
    <Pagination
      class="center"
      :active-first-last="false"
      :active-page="pagination.page"
      :page-count="pagination.lastPage"
      @previousPage="prevPage"
    />
  </div>
</template>

<style src="@/assets/styles/themes/default/tracks.css"></style>
